// extracted by mini-css-extract-plugin
export var singleProductGrid = "store-item-module--singleProductGrid--2VB__";
export var title = "store-item-module--title--Ccgbi";
export var price = "store-item-module--price--1KgwY";
export var selectButton = "store-item-module--selectButton--3dC2-";
export var addToCartButton = "store-item-module--addToCartButton--3w_qo";
export var basketIcon = "store-item-module--basketIcon--1oot6";
export var description = "store-item-module--description--20LKg";
export var imageWrapper = "store-item-module--imageWrapper--2tauV";
export var cart = "store-item-module--cart--1gEaU";
export var backToStore = "store-item-module--backToStore--HqrUN";